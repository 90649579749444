import './App.scss';
import webBg from '../images/bg-helio-web.jpg'
import mobileBg from '../images/bg-helio-mobile.jpg'
import logo from '../images/LOGOHELIOS.png'
import { isMobileOnly } from 'react-device-detect';
import { FiInstagram, FiMail } from 'react-icons/fi';


export default function App() {

  const handleClick = () => {
    window.open("https://www.instagram.com/latelierdhelios/");
  };

  return (
    <main className="page">

      <section className="content">

        <img className="logo" src={logo} alt="logo" />

        {!isMobileOnly ?
          <h1 className="title" > l'Atelier d'Hélios est en construction ! </h1>
          :
          <>
            <h1 className="title" > l'Atelier d'Hélios </h1>
            <h1 className="title" > est en construction ! </h1>
          </>}

        <h2 className='contact'>Vous avez un projet ? N'hésitez pas à nous contacter : </h2>

        <div className="links">

          <div onClick={handleClick} className="links-buttons">
            <FiInstagram className='links-icon' />
            <p> Instagram </p>
          </div>

          <div className="links-buttons">
            <FiMail className='links-icon' />
            <p> contact@atelierdhelios.com </p>
          </div>

        </div>
      </section>

      {!isMobileOnly ?
        <img className="webBg" src={webBg} alt="Working on our website" />
        :
        <img className="webBg" src={mobileBg} alt="Working on our website" />}

    </main>
  );
};

